<template>
  <b-row>
    <b-col md="4">
      <Can
        do="update"
        on="shipment"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-warning"
          @click.prevent="$router.push(`/shipment/${$route.params.id}/edit`)"
        >
          Edit
        </b-button>
      </Can>
      <h4 class="color-box mt-1">
        Signed By:
        <span class="invoice-number">{{ form.signed_by ? form.signed_by : '-' }}</span>
      </h4>
      <!--      <h6 class="color-box">
        Released Price:
        <span class="invoice-number">{{ form.released_price ? form.released_price : '-' }}</span>
      </h6>-->
      <b-overlay
        :show="loading"
        rounded="sm"
      >
        <Pod
          v-if="form"
          :pods="form.pods"
        />
      </b-overlay>
    </b-col>
    <b-col md="8">
      <b-overlay
        :show="loading"
        rounded="sm"
      >
        <b-row>
          <b-col md="5">
            <h6 class="font-large-1">
              COD:
              <span class="invoice-number">{{ form.cod_amount + form.fees }} LE</span>
            </h6>
          </b-col>
          <b-col md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              class="mb-75 action_button"
              variant="primary"
              @click="generateInvoices"
            >
              Generate Invoice
            </b-button>
          </b-col>
          <b-col md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              class="mb-75 action_button"
              variant="success"
              @click="printMiniShipments"
            >
              Print A5
            </b-button>
          </b-col>
          <b-col md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              class="mb-75 action_button"
              variant="success"
              @click="printShipments"
            >
              Print A4
            </b-button>
          </b-col>
        </b-row>
        <Can
          do="read"
          on="shipment"
        >
          <ShipmentDetails
            v-if="form"
            :data="form"
          />
        </Can>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { required } from '@core/utils/validations/validations'
import {
  BButton,
  BCol,
  BOverlay,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import Pod from '@/views/shipments/Pod'
// eslint-disable-next-line import/extensions
import ShipmentDetails from '@/views/shipments/ShipmentDetails'
import axios from '@/libs/axios'

export default {
  name: 'ShowVue',
  components: {
    ShipmentDetails,
    BRow,
    BCol,
    BButton,
    Pod,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        pods: [],
        status_id: '',
        released_price: '',
        signed_by: '',
      },
      required,
      errors: null,
      statuses: [],
      loading: true,
      delivered_status: JSON.parse(localStorage.getItem('settings')).delivered_status,
      released_status: JSON.parse(localStorage.getItem('settings')).released_status,
      notes: null,
      location: null,
      validationStatusCheck: false,
    }
  },
  mounted() {
    this.getData()
    this.getStatues()
  },
  methods: {
    getData() {
      axios.get(`/shipments/${this.$route.params.id}`).then(resp => {
        this.form = resp.data.data
        this.validationStatusCheck = this.validateStatus()
        this.loading = false
      })
    },
    getStatues() {
      axios.get('/statuses/').then(resp => {
        this.statuses = resp.data.data
      })
    },
    printShipments() {
      const shipmentsArr = [this.$route.params.id]
      const arrStr = encodeURIComponent(JSON.stringify(shipmentsArr))
      this.$router.push(`/shipment/print?shipments=${arrStr}`)
    },
    printMiniShipments() {
      const shipmentsArr = [this.$route.params.id]
      const arrStr = encodeURIComponent(JSON.stringify(shipmentsArr))
      this.$router.push(`/shipment/miniPrint?shipments=${arrStr}`)
    },
    generateInvoices() {
      const shipmentsArr = [this.$route.params.id]
      const arrStr = encodeURIComponent(JSON.stringify(shipmentsArr))
      this.$router.push(`/shipment/invoice?shipments=${arrStr}`)
    },
    validateStatus() {
      return this.form.status_id === window.Settings.delivered_status
          || this.form.status_id === window.Settings.consignee_delivered_status
          || this.form.status_id === window.Settings.returned_status
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
