<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon
          size="24"
          class="mr-1"
          icon="ListIcon"
        />
        <b-card-title class="ml-25">
          Shipment Status
        </b-card-title>
      </div>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
      />
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline>
        <!--                <app-timeline-item variant="primary">
                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>12 Invoices have been paid</h6>
                        <small class="timeline-item-time text-nowrap text-muted ml-1">12 min ago</small>
                    </div>
                    <p>Invoices have been paid to the company.</p>
                    <b-media no-body>
                        <b-media-aside class="mr-1">
                            <b-img
                                src=""
                                height="23"
                                alt="data.json"
                            />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="media-body mb-0">
                                data.json
                            </h6>
                        </b-media-body>
                    </b-media>
                </app-timeline-item>

                <app-timeline-item variant="warning">
                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>Client Meeting</h6>
                        <small class="timeline-item-time text-nowrap text-muted ml-1">45 min ago</small>
                    </div>
                    <p>Project meeting with john @10:15am</p>
                    <b-media no-body>
                        <b-media-aside class="mr-50">
                            <b-avatar
                                src=""
                                size="38"
                            />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                John Doe (Client)
                            </h6>
                            <p class="mb-0">
                                CEO of Infibeam
                            </p>
                        </b-media-body>
                    </b-media>
                </app-timeline-item>

                <app-timeline-item variant="info">
                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>Create a new project for client</h6>
                        <small class="timeline-item-time text-nowrap text-muted ml-1">2 day ago</small>
                    </div>
                    <p>Add files to new design folder</p>
                    <b-avatar-group size="35px">
                        <b-avatar
                            v-for="avatar in avatars"
                            :key="avatar.userImg"
                            v-b-tooltip.hover.top="'Tooltip!'"
                            :src="avatar.userImg"
                            class="pull-up"
                        />
                    </b-avatar-group>
                </app-timeline-item>-->

        <app-timeline-item
          v-for="pod in pods"
          :key="pod.id"
          :location="(pod.location ? pod.location : '')"
          :subtitle="(pod.notes ? pod.notes : '')"
          :time="pod.created_at | formatDate"
          :title="pod.status.name"
          :variant="pod.status.color"
        />
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
/* eslint-disable global-require */
export default {
  name: 'PodVue',
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['pods'],
  data() {
    return {
      avatars: [
        { userImg: '', name: 'Billy Hopkins' },
        { userImg: '', name: 'Amy Carson' },
        { userImg: '', name: 'Brandon Miles' },
        { userImg: '', name: 'Daisy Weber' },
        { userImg: '', name: 'Jenny Looper' },
      ],
    }
  },
}
</script>
